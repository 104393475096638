<template>
  <div class="container">
    <main>
      <section class="hero">
        <div class="hero-content">
          <h1>Improving Minimally Invasive Cancer Care</h1>
          <p>Enabling safe early cancer treatment via precise robot-guided ablation.</p>
          <a href="#additional-content" class="btn">Explore</a>
        </div>
      </section>

      <!-- Additional content sections -->
      <section class="additional-content">
        <div class="content-section">
          <div class="content">
            <div class="content-image center content-image-1"></div> <!-- Placeholder square image -->
            <div class="content-text">
              <h2>Simple</h2>
              <p>Learning curve and device setup are challenging with existing surgical robots. We fix that, providing an intuitive and straightforward user interface and design, letting your focus remain on the patient.</p>
            </div>
          </div>
        </div>
        <div class="content-section">
          <div class="content">
            <div class="content-image content-image-2"></div> <!-- Placeholder square image -->
            <div class="content-text">
              <h2>Efficient</h2>
              <p>Procedure time is important for you, the patient, and the hospital. Our device fits into your current workflow, consuming minimal OR space while helping you save time with fewer repositions.</p>
            </div>
          </div>
        </div>
        <div class="content-section">
          <div class="content">
            <div class="content-image content-image-3"></div> <!-- Placeholder square image -->
            <div class="content-text">
              <h2>Effective</h2>
              <p>We help you be more effective: reaching more patients with fewer complications and better outcomes. This provides your patients with better care and brings in more income to your department.</p>
            </div>
          </div>
        </div>
      </section>
      <!-- End of additional content sections -->
    </main>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  mounted() {
    // Add event listener to the button to trigger smooth scrolling
    document.querySelector('.btn').addEventListener('click', function(e) {
      e.preventDefault();
      // Get the height of the hero section
      const heroSectionHeight = document.querySelector('.hero').offsetHeight;
      // Get the position of the bottom of the hero section relative to the viewport
      const heroSectionBottom = document.querySelector('.hero').getBoundingClientRect().bottom;
      // Calculate the scroll position to the bottom of the hero section
      const scrollPosition = heroSectionHeight - window.innerHeight + heroSectionBottom;
      // Smooth scroll to the calculated position
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    });
  }
}
</script>

<style scoped>
.container {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.main {
  padding-top: 70px;
  text-align: center;
}

.hero {
  background-image: url('@/assets/background_mri.png');
  background-size: cover;
  background-position: right center; /* Prioritize right side */
  background-repeat: no-repeat;
  color: #fff;
  padding: 200px 0;
  width: 100vw;
  margin: 0;
  margin-left: -8px;
}

.hero-content {
  max-width: 600px;
  margin-left: 20px;
  text-align: left;
}

h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

p {
  font-size: 24px;
  margin-bottom: 30px;
}

.btn {
  display: inline-block;
  background-color: #66a3ff;
  color: #000;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #0056b3;
}

.content-section {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content {
  width: 100%; /* Adjusted width to take full width */
  display: flex;
  flex-direction: column; /* Stack content vertically on small screens */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.content-image {
  width: 300px; /* Adjust size as needed */
  height: 300px; /* Adjust size as needed */
  background-color: #ccc; /* Placeholder color */
  margin-right: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}

.content-image-1{
  background-image: url('@/assets/content_img_1.png');
}

.content-image-2{
  background-image: url('@/assets/content_img_2.png');
}

.content-image-3{
  background-image: url('@/assets/content_img_3.png');
}

.content-text {
  max-width: calc(100% - 170px); /* Adjusted max-width to accommodate image width */
}

.content-text h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

.content-text p {
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .content {
    flex-direction: column; /* Stack content vertically on small screens */
    align-items: center; /* Center content horizontally on small screens */
  }
  
  .content-image {
    width: 300px; /* Adjust size as needed */
    height: 300px; /* Adjust size as needed */
    background-size: cover;
    margin-bottom: 20px; /* Add margin bottom to separate image and text on small screens */
  }
  
}

@media screen and (min-width: 1280px) {
  .hero-content {
    margin-left: 10%; /* Adjust as needed */
    margin-right: auto; /* Set margin-right to auto for center alignment */
    max-width: 800px; /* Adjust max-width as needed */
  }

  h1 {
    font-size: 72px;
  }

  p {
    font-size: 36px;
  }

  .additional-content {
    display: flex; /* Use flexbox */
    justify-content: space-between; /* Distribute items evenly */
    margin-top: 50px;
  }

  .content {
    flex-direction: column; /* Stack content vertically on small screens */
    align-items: center; /* Center content horizontally on small screens */
  }
  
  .content-image {
    width: 400px; /* Adjust size as needed */
    height: 400px; /* Adjust size as needed */
    margin-bottom: 20px; /* Add margin bottom to separate image and text on small screens */
    background-size: cover;
  }
}
</style>
