<template>
  <div class="container">
    <h1>Contact Us</h1>
    <p class="message">Please reach out to us if you’d like to learn more!</p>
    <div class="contact-info">
      <p>Dimitri Schreiber – <a href="mailto:dimitri@air-surgical.com">dimitri@air-surgical.com</a></p>
    </div>
  </div>
</template>

<style scoped>
.container {
  text-align: center;
  background-image: url('@/assets/background_mri.png');
  background-size: cover;
  background-position: right center; /* Prioritize right side */
  background-repeat: no-repeat;
  color: #fff;
  padding: 200px 0;
  width: 100vw;
  margin: 0;
  margin-left: -8px;
}

h1, .message {
  color: #fff;
  margin-top: 20px; /* Adjusted for better spacing */
}

h1 {
  font-size: 36px;
  padding-bottom: 20px; /* Adjusted for better spacing */
}

p {
  font-size: 20px;
  margin-bottom: 30px;
}

.contact-info {
  color: #fff;
  margin-top: 20px; /* Added for better spacing */
}

.contact-info a {
  color: #fff; /* Set link color to white */
}
</style>