// routes/index.js

import { createRouter, createWebHistory } from 'vue-router';
import ContactPage from '@/components/ContactPage.vue';
import HomePage from '@/components/HomePage.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage // Use the component for the home page
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
